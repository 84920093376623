<template>
	<div class="wrap">
		<el-page-header @back="$router.go(-1)" content="营销推广" class="margin-bottom margin-top-xs"></el-page-header>
		<el-alert title="备注说明" type="success" class="margin-bottom">
			<div>
				推广收益目前仅针对移动端生效
			</div>
		</el-alert>
		<div class="form_box">
			<el-form ref="form" :model="form" label-width="140px">
				<el-form-item label="套餐推广收益比例" prop="setMeal" :rules="rules.required">
					<el-input-number v-model="form.setMeal" :precision="2" :min="0" :max="100"></el-input-number>
					<span class="margin-left">%</span>
				</el-form-item>
				<el-form-item label="项目推广收益比例" prop="serve" :rules="rules.required">
					<el-input-number v-model="form.serve" :precision="2" :min="0" :max="100"></el-input-number>
					<span class="margin-left">%</span>
				</el-form-item>
				<!-- <el-form-item label="商品推广收益比例" prop="value3" :rules="rules.required">
					<el-input-number v-model="form.value3" :precision="2" :min="0" :max="100"></el-input-number>
					<span class="margin-left">%</span>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="onSubmit">保存</el-button>
					<el-button @click="$router.go(-1)">取消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../common/common.rules";
	export default {
		data() {
			return {
				rules,
				form: {
					setMeal: '0.00',
					serve: '0.00'
				}
			};
		},

		mounted() {
			this.loadData();
		},
		methods: {
			loadData() {
				this.$api.store.marketingGet().then(res => {
					if (res.data) {
						this.form.serve = res.data.serve_ratio
						this.form.setMeal = res.data.set_meal_ratio
					}
				})
			},
			onSubmit() {
				console.log(this.form)
				this.$refs.form.validate(valid => {
					if (valid) {
						const loading = this.$loading();
						this.$api.store.marketingSet(this.form).then(res => {
								loading.close();
								console.log(res)
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`保存成功`);
									this.loadData()
									// this.$router.back();
								} else {
									this.$message.error(res.msg);
								}
							})
							.catch(err => {
								loading.close();
								this.$alert(err);
							});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			},
		}

	};
</script>
<style scoped>
	.form_box {
		max-width: 600px;
		padding-top: 30px;
	}
</style>
